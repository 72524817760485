import React from 'react'
import { Container } from '@mui/material'

const About: React.FC = () => {
    return (
        <Container>
            <h2>About Me</h2>
            <h3>Work</h3>
            <p>
                With over five years of dedicated experience as a Full-Stack Web
                Developer, I bring to the table a compelling fusion of technical
                expertise, charismatic leadership, and a knack for fostering
                collaboration. My proficiency spans across JavaScript, React,
                Redux, HTML/CSS, PHP/Twig, and UI architecture, complemented by
                an insatiable curiosity for emerging technologies. Drawing from
                my background as a former Retail Store Manager at Guitar Center,
                I've honed invaluable leadership skills and cultivated a talent
                for team collaboration. This unique blend positions me not only
                as a proficient developer but also as a mentor capable of
                guiding and inspiring peers towards collective success. My
                proficiency extends beyond individual skill sets; I have a
                robust foundation in component-based UI architectures, coupled
                with expertise in Sass and Webpack, enabling me to architect
                efficient systems and spearhead innovation with confidence. I am
                genuinely excited about opportunities that allow me to leverage
                both my technical prowess and leadership experience to
                contribute meaningfully to projects and teams alike. In essence,
                I am poised to drive excellence and innovation in web
                development, eager to embark on new challenges and make
                impactful contributions wherever I go.
            </p>
            <h3>Life</h3>
            <p>
                I am passionate about music, technology, and the great outdoors.
                I love spending time with my family, playing guitar, and
                exploring the beautiful Pacific Northwest. I enjoy immersing
                myself in the natural wonders of the region running, hiking, or
                bicycle riding. I am also a dedicated musician and have been
                singing, playing guitar and synthesizer, and producing music for
                over 20 years. My love for music extends beyond playing; I am
                also an avid collector of vinyl records and enjoy discovering
                new music and artists. I am a lifelong learner and am always
                seeking new opportunities to expand my knowledge and skills. I
                am excited about the future and look forward to the adventures
                that lie ahead.
            </p>
        </Container>
    )
}

export default About
